import * as React from "react";

const LegendWithCTA = ({ data }) => {
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-11 ">
            <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cell full-width" data-widget-type="cell" data-x="0" data-w="12">

                    <div className="row-fluid-wrapper row-depth-1 row-number-12 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget " style={{ height: "30px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_1554995576865766" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                            </div>
                        </div>
                    </div>

                    <div className="row-fluid-wrapper row-depth-1 row-number-13 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_15548369820411382" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_15548369820411382_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h3><strong>{data.legend}</strong></h3>
                                    <h3><span className="hs-cta-wrapper" id="hs-cta-wrapper-5730af32-f616-4c54-be35-9018aac9716e"><span className="hs-cta-node hs-cta-5730af32-f616-4c54-be35-9018aac9716e" id="hs-cta-5730af32-f616-4c54-be35-9018aac9716e" style={{ visibility: "visible" }} data-hs-drop="true"><a id="cta_button_2642996_7fc1dd65-60ad-407e-a5ab-42f63fed513c" className="cta_button " href={data.ctaUrl} target="_blank" rel="noopener"  title={data.ctaLabel}><span style={{ fontSize: "14px" }}>{data.ctaLabel}</span></a></span></span></h3></span></div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default LegendWithCTA;