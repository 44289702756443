import * as React from "react";
import Layout from "../components/global/Layout";
import Hero from "../components/home/Hero";
import LegendWithCTA from "../components/global/LegendWithCTA";
import TwoColumnCTAs from "../components/home/TwoColumnCTAs";
import SharedDecision from "../components/home/SharedDecision";
import TestInfo from "../components/home/TestInfo";
import TestResources from "../components/home/TestResources";
import { useHomeQuery } from "../hooks/useHomeQuery";
import Helmet from "react-helmet";

const IndexPage = () => {
  const data = useHomeQuery();
  return (
    <Layout>
      <Hero data={data.heroSection} />
      <LegendWithCTA data={data.ctaSection} />
      <TwoColumnCTAs data={data.twoColumnsCtas} />
      <SharedDecision data={data.sharedDecision} />
      <TestInfo data={data.infoSection} />
      <TestResources data={data.testResources} />
      <Helmet>
        <title>{data.homeMetaData.metaTitle}</title>
        <meta name="description" content={data.homeMetaData.metaDescription} />
      </Helmet>
    </Layout>
  )
};

export default IndexPage;
