import * as React from "react";

const TestInfo = ({data}) => {
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-34 ">
            <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cell full-width sp_home_steps_outer" style={{backgroundImage: "url('"+data.image.publicUrl+"')", backgroundSize: "cover", backgroundPosition: "center top"}} data-widget-type="cell" data-x="0" data-w="12">

                    <div className="row-fluid-wrapper row-depth-1 row-number-35 ">
                        <div className="row-fluid ">
                            <div className="span6 widget-span widget-type-cell sp_home_steps" style={{marginLeft: "80px"}} data-widget-type="cell" data-x="0" data-w="6">

                                <div className="row-fluid-wrapper row-depth-1 row-number-36 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{height: "60px"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_1535637155143231" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-37 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_1554996401918955" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-38 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_1535637231698239" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1535637231698239_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text" dangerouslySetInnerHTML={{ __html: data.content }}></span></div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-39 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget sp_red_btn" style={{paddingTop: "2%"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_1535637672064250" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-cta widget-type-cta" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1535637672064250_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_cta" data-hs-cos-general-type="widget" data-hs-cos-type="cta"></span></div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-40 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_1535638401679256" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="span6 widget-span widget-type-cell " data-widget-type="cell" data-x="6" data-w="6">

                            </div>
                        </div>
                    </div>

                    <div className="row-fluid-wrapper row-depth-1 row-number-41 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{height:"100px"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_1535637216005233" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default TestInfo;