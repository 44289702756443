import * as React from "react";

const Hero = ({ data }) => {
    return (
        <div>
            <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-custom_widget hidden-desktop sp_mobile_hero" data-widget-type="custom_widget" data-x="0" data-w="12">
                        <div id="hs_cos_wrapper_module_1568900258652145" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-linked_image" data-hs-cos-general-type="widget" data-hs-cos-type="module">

                            <span id="hs_cos_wrapper_module_1568900258652145_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_linked_image" data-hs-cos-general-type="widget" data-hs-cos-type="linked_image"><img src={data.backgroundImage.publicUrl} className="hs-image-widget " style={{ maxWidth: "100%", height: "auto" }} /></span></div>

                    </div>
                </div>
            </div>
            <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-custom_widget hidden-desktop sp_mobile_hero" style={{ color: "#c33c33 !important" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                        <div id="hs_cos_wrapper_module_1568900631055208" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1568900631055208_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h1 style={{textAlign: "center", color: "#c33c33 !important", fontFamily: "BrutalType-Bold,Sans-Serif"}}><div dangerouslySetInnerHTML={{ __html: data.text}}></div></h1></span></div>

                    </div>
                </div>
            </div>
            <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-cell sp_main_hero sp_grey_bkg hidden-phonefull width" style={{backgroundImage: "url('"+data.backgroundImage.publicUrl+"')", backgroundSize: "cover", backgroundPosition: "center top", backgroundRepeat: "no-repeat",position: "relative"}} data-widget-type="cell" data-x="0" data-w="12">

                        <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-cell full-width" data-widget-type="cell" data-x="0" data-w="12">

                                    <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
                                        <div className="row-fluid ">
                                            <div className="span6 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="6">

                                                <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
                                                    <div className="row-fluid ">
                                                        <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{height: "140px"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                                                            <div id="hs_cos_wrapper_module_1555284775877179" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
                                                    <div className="row-fluid ">
                                                        <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                            <div id="hs_cos_wrapper_module_1555284775877180" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row-fluid-wrapper row-depth-1 row-number-8 ">
                                                    <div className="row-fluid ">
                                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                            <div id="hs_cos_wrapper_module_1555284775877181" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1555284775877181_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h1><div dangerouslySetInnerHTML={{ __html: data.text}}></div></h1></span></div>

                                                            </div>
                                                            </div>
                                                        </div>

                                                        <div className="row-fluid-wrapper row-depth-1 row-number-9 ">
                                                            <div className="row-fluid ">
                                                                <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                    <div id="hs_cos_wrapper_module_1555284775877182" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row-fluid-wrapper row-depth-1 row-number-10 ">
                                                            <div className="row-fluid ">
                                                                <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{height: "120px"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                    <div id="hs_cos_wrapper_module_1555284775877183" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="span6 widget-span widget-type-cell " data-widget-type="cell" data-x="6" data-w="6">

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                )
};

                export default Hero;