import * as React from "react";

const SharedDecision = ({data}) => {
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-17 ">
            <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cell " style={{ backgroundColor: "rgb(249,249,249)" }} data-widget-type="cell" data-x="0" data-w="12">

                    <div className="row-fluid-wrapper row-depth-1 row-number-18 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-cell full-width sp_vert_align sp_text_img_2col" style={{ backgroundImage: "url('"+data.desktopimg.publicUrl+"')", backgroundSize: "cover", backgroundPosition: "center top" }} data-widget-type="cell" data-x="0" data-w="12">

                                <div className="row-fluid-wrapper row-depth-1 row-number-19 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget sp_tablet" data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_1555277083794593" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module">
                                                <img src={data.mobileimg.publicUrl} style={{ width: "100%", display: "block", margin: "0px auto" }} />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-20 ">
                                    <div className="row-fluid ">
                                        <div className="span5 widget-span widget-type-cell homePad" data-widget-type="cell" data-x="0" data-w="5">

                                            <div className="row-fluid-wrapper row-depth-1 row-number-21 ">
                                                <div className="row-fluid ">
                                                    <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{ height: "40px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                                        <div id="hs_cos_wrapper_module_1532540409139424" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row-fluid-wrapper row-depth-1 row-number-22 ">
                                                <div className="row-fluid ">
                                                    <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                        <div id="hs_cos_wrapper_module_1538852519278138" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row-fluid-wrapper row-depth-1 row-number-23 ">
                                                <div className="row-fluid ">
                                                    <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                        <div id="hs_cos_wrapper_module_1532488862268775" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1532488862268775_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text" dangerouslySetInnerHTML={{ __html: data.content }}></span></div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row-fluid-wrapper row-depth-1 row-number-24 ">
                                                <div className="row-fluid ">
                                                    <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                        <div id="hs_cos_wrapper_module_153253439264325" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row-fluid-wrapper row-depth-1 row-number-25 ">
                                                <div className="row-fluid ">
                                                    <div className="span12 widget-span widget-type-custom_widget sp_red_btn" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                        <div id="hs_cos_wrapper_module_153253440796230" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-cta widget-type-cta" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_153253440796230_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_cta" data-hs-cos-general-type="widget" data-hs-cos-type="cta"><span className="hs-cta-wrapper" id="hs-cta-wrapper-f1c9dfb4-8ad0-4cf4-9b30-69508b641f7b"><span className="hs-cta-node hs-cta-f1c9dfb4-8ad0-4cf4-9b30-69508b641f7b" style={{ visibility: "visible" }} data-hs-drop="true"><a className="cta_button learnMore ctabtnfix2" href={data.ctaUrl} target="_blank">{data.ctaLabel}</a></span></span></span></div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row-fluid-wrapper row-depth-1 row-number-26 ">
                                                <div className="row-fluid ">
                                                    <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                        <div id="hs_cos_wrapper_module_153263292239135" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row-fluid-wrapper row-depth-1 row-number-27 ">
                                                <div className="row-fluid ">
                                                    <div className="span12 widget-span widget-type-custom_widget " style={{ height: "60px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                                        <div id="hs_cos_wrapper_module_1555275318657351" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="span7 widget-span widget-type-cell " data-widget-type="cell" data-x="5" data-w="7">

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SharedDecision;