import * as React from "react";

const TwoColumnCTAs = ({ data }) => {
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-14 ">
            <div className="row-fluid ">
                <div id="sp_home_cta" className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                    <div className="row-fluid-wrapper row-depth-1 row-number-15 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_15548343270321248" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="locatorsWrapper">

                                    <div className="locatorsBoxes">
                                        <img style={{width:"100%"}} src={data.column1.image.publicUrl} alt="" />
                                        <div className="locatorsContent clearfix"><h3 style={{textAlign: "center", marginTop: "-10px", marginLeft: "-20px", marginRight: "-20px"}}><span style={{color: "#ffffff"}} dangerouslySetInnerHTML={{ __html: data.column1.columnTitle }}></span></h3>
                                            <div className="sp_home_cta_btns">
                                                <div className="sp_home_cta_btn_half"><span className="hs-cta-wrapper" id="hs-cta-wrapper-bee1922c-133a-4f7c-badf-02f565c5b4ac"><span className="hs-cta-node hs-cta-bee1922c-133a-4f7c-badf-02f565c5b4ac" style={{visibility: "visible"}} data-hs-drop="true"><a className="cta_button sp_grey_btn ctacolunsbtnfix" style={{display: "inline-block"}} href={data.column1.cta1Url} dangerouslySetInnerHTML={{ __html: data.column1.cta1Label }}></a></span></span></div>
                                                <div className="sp_home_cta_btn_half"><span className="hs-cta-wrapper" id="hs-cta-wrapper-2618c6a2-7ae2-4f8f-a562-f7b37b6a14aa"><span className="hs-cta-node hs-cta-2618c6a2-7ae2-4f8f-a562-f7b37b6a14aa" style={{visibility: "visible"}} data-hs-drop="true"><a className="cta_button sp_grey_btn ctacolunsbtnfix" href={data.column1.cta2Url} dangerouslySetInnerHTML={{ __html: data.column1.cta2Label }}></a></span></span></div>
                                            </div></div>
                                    </div>

                                    <div className="locatorsBoxes">
                                        <img style={{width:"100%"}} src={data.column2.image.publicUrl} alt="" />
                                        <div className="locatorsContent clearfix"> <h3 style={{textAlign: "center", marginTop: "-10px", marginLeft: "-20px", marginRight: "-20px"}}><span style={{color: "#ffffff"}} dangerouslySetInnerHTML={{ __html: data.column2.columnTitle }}></span></h3>
                                            <div className="sp_home_cta_btns">
                                                <div className="sp_home_cta_btn_half"><span className="hs-cta-wrapper"><span className="hs-cta-node hs-cta-bee1922c-133a-4f7c-badf-02f565c5b4ac" style={{visibility: "visible"}} data-hs-drop="true"><a id="cta_button_2642996_0e22bf88-4c62-4f11-969a-aa4644a76f9d" className="cta_button sp_grey_btn" href={data.column2.cta1Url} dangerouslySetInnerHTML={{ __html: data.column2.cta1Label }}></a></span></span></div>
                                                <div className="sp_home_cta_btn_half"><span className="hs-cta-wrapper"><span className="hs-cta-node hs-cta-2618c6a2-7ae2-4f8f-a562-f7b37b6a14aa" style={{visibility: "visible"}} data-hs-drop="true"><a id="cta_button_2642996_a50a8bc0-bf02-40bd-8713-3ca54f12279f" className="cta_button sp_grey_btn" href={data.column2.cta2Url} dangerouslySetInnerHTML={{ __html: data.column2.cta2Label }}></a></span></span></div>
                                            </div></div>
                                    </div>

                                </div></div>

                            </div>
                        </div>
                    </div>

                    <div className="row-fluid-wrapper row-depth-1 row-number-16 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget " style={{height: "40px"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_1554995530970706" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default TwoColumnCTAs;