import * as React from "react";

const TestResources = ({data}) => {
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-43 " style={{marginTop: "60px"}}>
            <div className="row-fluid ">
                <div id="home-4k-resources" className="span12 widget-span widget-type-cell section--grid bg--gray" data-widget-type="cell" data-x="0" data-w="12">

                    <div className="row-fluid-wrapper row-depth-1 row-number-44 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-cell container" data-widget-type="cell" data-x="0" data-w="12">

                                <div className="row-fluid-wrapper row-depth-1 row-number-45 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_15768227128611009" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_15768227128611009_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h2 className="headlines" style={{textAlign: "center", fontWeight: "normal"}}>The 4Kscore® Test Resources</h2></span></div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-46 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-cell grid" data-widget-type="cell" data-x="0" data-w="12">

                                            <div className="row-fluid-wrapper row-depth-1 row-number-47 ">
                                                <div className="row-fluid ">
                                                    <div className="span3 widget-span widget-type-cell grid-item" data-widget-type="cell" data-x="0" data-w="4">

                                                        <div className="row-fluid-wrapper row-depth-2 row-number-1 ">
                                                            <div className="row-fluid ">
                                                                <div className="span12 widget-span widget-type-custom_widget grid-item--content" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                    <div id="hs_cos_wrapper_module_15768227128611012" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_15768227128611012_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h3 className="headlines">{data.column1.headline}</h3>
                                                                        <div dangerouslySetInnerHTML={ {__html: data.column1.content} }></div>
                                                                        <p><span className="hs-cta-wrapper" id="hs-cta-wrapper-c2ba1d31-07e9-470e-b498-1af96a77ad85"><span className="hs-cta-node hs-cta-c2ba1d31-07e9-470e-b498-1af96a77ad85" id="hs-cta-c2ba1d31-07e9-470e-b498-1af96a77ad85" style={{visibility: "visible"}} data-hs-drop="true"><a id="cta_button_2642996_396d15be-308e-4235-aa0b-13bb2e0779f2" className="cta_button learnMore columnresbutton" href={data.column1.ctaUrl}><div><span dangerouslySetInnerHTML={{ __html: data.column1.ctaLabel}}></span></div></a></span></span></p></span></div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="span3 widget-span widget-type-cell grid-item" data-widget-type="cell" data-x="3" data-w="4">

                                                        <div className="row-fluid-wrapper row-depth-2 row-number-2 ">
                                                            <div className="row-fluid ">
                                                                <div className="span12 widget-span widget-type-custom_widget grid-item--content" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                    <div id="hs_cos_wrapper_module_15768227128611014" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_15768227128611014_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h3 className="headlines">{data.column2.headline}</h3>
                                                                        <div className="centered_text" dangerouslySetInnerHTML={{ __html: data.column2.content}}></div><p><span className="hs-cta-wrapper" id="hs-cta-wrapper-7e59535c-7747-4935-82d4-7edca2d54df7"><span className="hs-cta-node hs-cta-7e59535c-7747-4935-82d4-7edca2d54df7" id="hs-cta-7e59535c-7747-4935-82d4-7edca2d54df7" style={{visibility: "visible"}} data-hs-drop="true"><a id="cta_button_2642996_c6eaf7a1-e4f6-4ea6-8062-f14f68d00423" className="cta_button learnMore columnresbutton" href={data.column2.ctaUrl}><div><span dangerouslySetInnerHTML={{ __html: data.column2.ctaLabel }}></span></div></a></span></span></p></span></div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="span3 widget-span widget-type-cell grid-item" data-widget-type="cell" data-x="6" data-w="4">

                                                        <div className="row-fluid-wrapper row-depth-2 row-number-3 ">
                                                            <div className="row-fluid ">
                                                                <div className="span12 widget-span widget-type-custom_widget grid-item--content" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                    <div id="hs_cos_wrapper_module_15768227128611016" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_15768227128611016_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h3 className="headlines">{data.column3.headline}</h3>
                                                                        <div className="centered_text" dangerouslySetInnerHTML={{ __html: data.column3.content}}></div><p><span className="hs-cta-wrapper" id="hs-cta-wrapper-c1ca027f-0fb1-4e08-aefe-c3897ec1b85a"><span className="hs-cta-node hs-cta-c1ca027f-0fb1-4e08-aefe-c3897ec1b85a" id="hs-cta-c1ca027f-0fb1-4e08-aefe-c3897ec1b85a" style={{visibility: "visible"}} data-hs-drop="true"><a id="cta_button_2642996_d62f7245-5e74-4733-aeaa-25cfee9d8aaa" className="cta_button learnMore columnresbutton" href={data.column3.ctaUrl} dangerouslySetInnerHTML={{ __html: data.column3.ctaLabel}}></a></span></span>&nbsp;</p></span></div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {/* <div className="span3 widget-span widget-type-cell grid-item" data-widget-type="cell" data-x="9" data-w="3">

                                                        <div className="row-fluid-wrapper row-depth-2 row-number-4 ">
                                                            <div className="row-fluid ">
                                                                <div className="span12 widget-span widget-type-custom_widget grid-item--content" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                    <div id="hs_cos_wrapper_module_15768227128611018" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_15768227128611018_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h3 className="headlines">{data.column4.headline}</h3>
                                                                        <div className="centered_text" dangerouslySetInnerHTML={{__html: data.column4.content}}></div></span></div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div> */}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_15768227128611020" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_15768227128611020_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text" dangerouslySetInnerHTML={{ __html: data.ref}}></span></div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_15768227128611021" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default TestResources;
