import { useStaticQuery, graphql } from "gatsby"

export const useHomeQuery = () => {


    const data = useStaticQuery(graphql`
    query HomeQuery {
        wpPage(title: {eq: "HOME"}) {
          ACF_HomePage {

            heroSection {
                backgroundImage {
                    publicUrl
                }
                text
            }

            ctaSection {
                legend
                ctaLabel
                ctaUrl
            }

            twoColumnsCtas {
                column1 {
                    image {
                        publicUrl
                    }
                    cta1Label
                    cta1Url
                    cta2Label
                    cta2Url
                    columnTitle
                }
                column2 {
                    image {
                        publicUrl
                    }
                    cta1Label
                    cta1Url
                    cta2Label
                    cta2Url
                    columnTitle
                }
            }

            sharedDecision {
                desktopimg {
                    publicUrl
                }
                mobileimg {
                    publicUrl
                }
                content
                ctaLabel
                ctaUrl
            }

            infoSection {
                image {
                    publicUrl
                }
                content
            }

            testResources {
                column1 {
                    headline
                    content
                    ctaLabel
                    ctaUrl
                }
                column2 {
                    headline
                    content
                    ctaLabel
                    ctaUrl
                }
                column3 {
                    headline
                    content
                    ctaLabel
                    ctaUrl
                }
                column4 {
                    headline
                    content
                }
                ref
            }

            homeMetaData {
                metaTitle
                metaDescription
            }
        
        
        }
        }
      }     
    `)
    return data.wpPage.ACF_HomePage;
}